import React from "react"
import { Link } from "gatsby"

const NotFound = () => {
  return (
    <>
      Pagina niet gevonden. <Link to="/">terug naar begin</Link>
    </>
  )
}

export default NotFound
